import { inject as service } from '@ember/service'
import Controller from '@ember/controller'
import { action } from '@ember/object'
import { underscore } from '@ember/string'
import config from 're-client/config/environment'
import type StudentProgressService from 're-client/services/student-progress'
import type Operations from '@blakeelearning/student-operations/operations/service'
import type RouterService from '@ember/routing/router-service'
import type UserService from 're-client/services/user'
import type AssignmentsService from 're-client/services/assignments'

const {
  APP: { product },
} = config

interface DrivingTestResults {
  quiz: number
  test_type: string
  correct_answers: string[]
  incorrect_answers: Record<string, string>
  passed: boolean
}

interface DrivingTestQuizResults extends DrivingTestResults {
  category: string
  grade_position?: number
}

interface StudentContext {
  product: string
  precinct: string
  remoteId: string
  assignmentUuid?: string
  taskId?: string
}

export default class DrivingTestsQuizController extends Controller {
  @service declare studentProgress: StudentProgressService

  @service declare operations: Operations

  @service declare router: RouterService

  @service declare user: UserService

  @service declare assignments: AssignmentsService

  get studentContext() {
    return {
      product,
      precinct: 'driving_tests',
      remoteId: this.user.student.remoteId,
    }
  }

  @action
  goToLobby() {
    void this.router.transitionTo('driving-tests')
  }

  @action
  startRace() {
    void this.router.transitionTo('driving-tests.racing-game')
  }

  @action
  async completeDrivingTest(results: DrivingTestResults) {
    this.user.setRacingGameAvailability(results.passed)

    const category = underscore(results.test_type)
    const studentContext: StudentContext = this.studentContext
    const result: DrivingTestQuizResults = {
      ...results,
      category,
    }

    if (
      this.assignments.currentTask?.__typename ===
        'AssignmentTaskDrivingTests' &&
      this.assignments.canCompleteDrivingTestAssignmentTask(
        results.quiz,
        category,
      )
    ) {
      const {
        gradePosition,
        assignmentUuid,
        id: taskId,
      } = this.assignments.currentTask

      result.grade_position = gradePosition
      studentContext.taskId = taskId
      studentContext.assignmentUuid = assignmentUuid
    }

    await this.operations.completeDrivingTestsQuiz(studentContext, result)

    await this.assignments.fetch()

    return this.studentProgress.fetchProgress()
  }
}
